import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ExternalLink from '../../components/ExternalLink';
import LeadParagraph from '../../components/LeadParagraph';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "what-is-new",
      "style": {
        "position": "relative"
      }
    }}>{`What is new`}<a parentName="h1" {...{
        "href": "#what-is-new",
        "aria-label": "what is new permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
    Here you will find summarized patch notes of major releases of HDS. For full patch notes for each release, please refer to the <ExternalLink size="L" href="https://github.com/City-of-Helsinki/helsinki-design-system/releases" mdxType="ExternalLink">GitHub releases</ExternalLink>.
    </LeadParagraph>
    <h2 {...{
      "id": "releases",
      "style": {
        "position": "relative"
      }
    }}>{`Releases`}<a parentName="h2" {...{
        "href": "#releases",
        "aria-label": "releases permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "250",
      "style": {
        "position": "relative"
      }
    }}>{`2.5.0`}<a parentName="h3" {...{
        "href": "#250",
        "aria-label": "250 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`1.11.2022`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v2.5.0" external mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <h3 {...{
      "id": "240",
      "style": {
        "position": "relative"
      }
    }}>{`2.4.0`}<a parentName="h3" {...{
        "href": "#240",
        "aria-label": "240 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`10.10.2022`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v2.4.0" external mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: SSR Support for React components`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: 13 new UI icons`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Cookie consent language change doesn't lose focus`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Link component has support for aria-label for better accessibility`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Accessibity issues with TimeInput component`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{` Fixed warning with useTheme hook with jest/testing-library`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{` Loki tests failing with Apple Silicon (M1)`}</li>
    </ul>
    <h3 {...{
      "id": "230",
      "style": {
        "position": "relative"
      }
    }}>{`2.3.0`}<a parentName="h3" {...{
        "href": "#230",
        "aria-label": "230 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`14.9.2022`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v.2.3.0" external mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: React CookieModal component`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: React CookiePage component`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New pattern Cookie compliance`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New pattern Common Helsinki cookies`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New pattern Cookie consent`}</li>
    </ul>
    <h3 {...{
      "id": "220",
      "style": {
        "position": "relative"
      }
    }}>{`2.2.0`}<a parentName="h3" {...{
        "href": "#220",
        "aria-label": "220 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`8.9.2022`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v2.2.0" external mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: React Pagination component`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Table onSort property for custom sorting with side effects`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Notification notificationAriaLabel property to improve accessibility`}</li>
    </ul>
    <h3 {...{
      "id": "211",
      "style": {
        "position": "relative"
      }
    }}>{`2.1.1`}<a parentName="h3" {...{
        "href": "#211",
        "aria-label": "211 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`12.7.2022`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v2.1.1" external mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Navigation title font size styles`}</li>
    </ul>
    <h3 {...{
      "id": "210",
      "style": {
        "position": "relative"
      }
    }}>{`2.1.0`}<a parentName="h3" {...{
        "href": "#210",
        "aria-label": "210 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`30.6.2022`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v2.1.0" external mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Navigation DropdownLink`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Dialog unwanted content scrolling`}</li>
    </ul>
    <h3 {...{
      "id": "200",
      "style": {
        "position": "relative"
      }
    }}>{`2.0.0`}<a parentName="h3" {...{
        "href": "#200",
        "aria-label": "200 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`27.6.2022`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v2.0.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Change`}</strong>{`: Typography updates in multiple components to match new hel.fi visual identity`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New documentation site`}</li>
      <li parentName="ul"><strong parentName="li">{`Change`}</strong>{`: Container component maximum width is restricted only with the --container-width-xl value`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Grayscale design tokens' values to match the percentage names`}</li>
      <li parentName="ul"><strong parentName="li">{`Removed`}</strong>{`: Deprecated theme variables`}</li>
    </ul>
    <h2 {...{
      "id": "releases-before-the-version-20",
      "style": {
        "position": "relative"
      }
    }}>{`Releases before the version 2.0`}<a parentName="h2" {...{
        "href": "#releases-before-the-version-20",
        "aria-label": "releases before the version 20 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "1150",
      "style": {
        "position": "relative"
      }
    }}>{`1.15.0`}<a parentName="h3" {...{
        "href": "#1150",
        "aria-label": "1150 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`30.5.2022`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.14.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: AriaLabel property to Navigation and SideNavigation to distinguish different navigational components`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: SideNavigation keyboard navigating on smaller screens`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: initializing of useMobile hook. Used in Navigation component`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Tabs' Tab OnClick callback is called also when a tab is selected with the keyboard`}</li>
    </ul>
    <h3 {...{
      "id": "1140",
      "style": {
        "position": "relative"
      }
    }}>{`1.14.0`}<a parentName="h3" {...{
        "href": "#1140",
        "aria-label": "1140 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`5.5.2022`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.14.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: className prop for dialog action button container to enable customisation`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: ref prop for Link to fix preloading content with Next.js framework`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Support for React 17`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Wrapping of dialog action buttons on desktop`}</li>
    </ul>
    <h3 {...{
      "id": "1130",
      "style": {
        "position": "relative"
      }
    }}>{`1.13.0`}<a parentName="h3" {...{
        "href": "#1130",
        "aria-label": "1130 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`20.4.2022`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.13.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Pagination Core component`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Dialog re-rendering when content state changes`}</li>
    </ul>
    <h3 {...{
      "id": "1120",
      "style": {
        "position": "relative"
      }
    }}>{`1.12.0`}<a parentName="h3" {...{
        "href": "#1120",
        "aria-label": "1120 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`22.3.2022`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.12.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Indeterminate Checkbox state`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Tabs initiallyActiveTab and Tab onClick properties`}</li>
      <li parentName="ul"><strong parentName="li">{`Removed`}</strong>{`: Node engine restriction`}</li>
    </ul>
    <h3 {...{
      "id": "1111",
      "style": {
        "position": "relative"
      }
    }}>{`1.11.1`}<a parentName="h3" {...{
        "href": "#1111",
        "aria-label": "1111 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`8.3.2022`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.11.1" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Core table header's default background color`}</li>
    </ul>
    <h3 {...{
      "id": "1110",
      "style": {
        "position": "relative"
      }
    }}>{`1.11.0`}<a parentName="h3" {...{
        "href": "#1110",
        "aria-label": "1110 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`23.2.2022`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.11.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Stepper component`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Inclusive design guideline`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Multi-page form patterns documentation`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Box-shadow design token`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Box shadow support for elevated components: Card, Dialog, Notification, and Tooltip`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Dense property variant for Koros types`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Clear-button support for single Select and Combobox`}</li>
    </ul>
    <h3 {...{
      "id": "1100",
      "style": {
        "position": "relative"
      }
    }}>{`1.10.0`}<a parentName="h3" {...{
        "href": "#1100",
        "aria-label": "1100 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`31.1.2022`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.10.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Thumb icons`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Documentation guideline page for Koros`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Add support to use Tabs child components in Tabs namespace, for example, Tabs.TabList`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Footer's back-to-top will focus on the first focusable element`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Prevent text inputs' rounded corners in iOS`}</li>
    </ul>
    <h3 {...{
      "id": "190",
      "style": {
        "position": "relative"
      }
    }}>{`1.9.0`}<a parentName="h3" {...{
        "href": "#190",
        "aria-label": "190 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`5.1.2022`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.9.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Possibility to rotate Koros`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Support for a placeholder in Search Input`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: WP and Drupal notes to the developers’ Getting Started documentation`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: An example of asking the user’s gender in a form in the Form pattern documentation`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Resolved critical security threats in the new documentation site`}</li>
    </ul>
    <h3 {...{
      "id": "180",
      "style": {
        "position": "relative"
      }
    }}>{`1.8.0`}<a parentName="h3" {...{
        "href": "#180",
        "aria-label": "180 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`9.12.2021`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.8.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: 9 new icons`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Support for customising Tabs with theme`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Support for Search Input `}<inlineCode parentName="li">{`onChange`}</inlineCode>{` property`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Support for hiding Search Input's search button`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Support for icons in multi-select variants of Select and Combobox components`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Fix previous and next month focus disappearing in DateInput's Datepicker`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Clicking Search Input's suggestion triggers onSubmit`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Removing selectedItem with the Enter key press and with space keypress in Search Input`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Add focus outline to Buttons to preserve outline on click also in Safari`}</li>
    </ul>
    <h3 {...{
      "id": "170",
      "style": {
        "position": "relative"
      }
    }}>{`1.7.0`}<a parentName="h3" {...{
        "href": "#170",
        "aria-label": "170 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`25.11.2021`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.7.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: React Table component`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Favicon documentation`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: DateInput supports disabling dates based on custom logic`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Logo language and size variants documentation`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Resetting Select value to null`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: DateInput's datepicker keyboard navigation when min or max date is set`}</li>
    </ul>
    <h3 {...{
      "id": "160",
      "style": {
        "position": "relative"
      }
    }}>{`1.6.0`}<a parentName="h3" {...{
        "href": "#160",
        "aria-label": "160 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`28.10.2021`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.6.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Table to HDS core`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Calm variant of Koros`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Notification animations not firing with some build configurations, often in production builds`}</li>
    </ul>
    <h3 {...{
      "id": "150",
      "style": {
        "position": "relative"
      }
    }}>{`1.5.0`}<a parentName="h3" {...{
        "href": "#150",
        "aria-label": "150 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`22.9.2021`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.5.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: File Input`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Info text to inform the user of dynamic input value changes`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Shopping cart icon`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Accordion to open via header click`}</li>
    </ul>
    <h3 {...{
      "id": "140",
      "style": {
        "position": "relative"
      }
    }}>{`1.4.0`}<a parentName="h3" {...{
        "href": "#140",
        "aria-label": "140 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`7.9.2021`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.4.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Linkbox`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: SideNavigation`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Guideline page for choosing between checkboxes, radiobuttons, and toggles`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Whatsapp icon`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: NumberInput onChange event when using stepper buttons`}</li>
    </ul>
    <h3 {...{
      "id": "131",
      "style": {
        "position": "relative"
      }
    }}>{`1.3.1`}<a parentName="h3" {...{
        "href": "#131",
        "aria-label": "131 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`11.8.2021`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.3.1" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Color Spåra (color-tram) token value from `}<inlineCode parentName="li">{`#009246`}</inlineCode>{` to `}<inlineCode parentName="li">{`#008741`}</inlineCode>{` to achieve better accessibility`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Added support for clearing DateInput value programmatically`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Multiple bug fixes`}</li>
    </ul>
    <h3 {...{
      "id": "130",
      "style": {
        "position": "relative"
      }
    }}>{`1.3.0`}<a parentName="h3" {...{
        "href": "#130",
        "aria-label": "130 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`9.8.2021`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v.1.3.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Link`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Upcoming breaking changes page which lists near future changes that may be considered breaking`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Guideline page for contributing for implementation`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Tutorial page for getting started with Abstract`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Multiple bug fixes`}</li>
    </ul>
    <h3 {...{
      "id": "120",
      "style": {
        "position": "relative"
      }
    }}>{`1.2.0`}<a parentName="h3" {...{
        "href": "#120",
        "aria-label": "120 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`21.6.2021`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.2.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Fieldset`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Toggle button`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Documentation and guidelines for creating new HDS icons`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Documentation for error messages related to multiple input fields`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Renamed all “Fixed” components to “Read-only” in the Design kit to match naming used in the implementation`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Text input error and success text alignments`}</li>
    </ul>
    <h3 {...{
      "id": "110",
      "style": {
        "position": "relative"
      }
    }}>{`1.1.0`}<a parentName="h3" {...{
        "href": "#110",
        "aria-label": "110 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`25.5.2021`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.1.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Password input field`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Examples for colour usage`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Icon variations for labels`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: colour usage examples`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Improvements for sizing in tags and labels`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Improvements for colour usage documentation`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Date picker positioning`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Improvements for Storybook examples loading time`}</li>
    </ul>
    <h3 {...{
      "id": "100",
      "style": {
        "position": "relative"
      }
    }}>{`1.0.0`}<a parentName="h3" {...{
        "href": "#100",
        "aria-label": "100 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`3.5.2021`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v1.0.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Documentation and component statuses updated for the 1.0 version`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Small fixes and improvements for multiple components`}</li>
      <li parentName="ul"><strong parentName="li">{`Removed`}</strong>{`: Previously deprecated Dropdown component`}</li>
    </ul>
    <h2 {...{
      "id": "releases-before-the-version-10",
      "style": {
        "position": "relative"
      }
    }}>{`Releases before the version 1.0`}<a parentName="h2" {...{
        "href": "#releases-before-the-version-10",
        "aria-label": "releases before the version 10 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "0250-beta",
      "style": {
        "position": "relative"
      }
    }}>{`0.25.0 (beta)`}<a parentName="h3" {...{
        "href": "#0250-beta",
        "aria-label": "0250 beta permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`14.4.2021`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v0.25.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Phone input component`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Dialog component`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Select component content overlap with long labels`}</li>
      <li parentName="ul"><strong parentName="li">{`Removed`}</strong>{`: Deprecated labelText property from TextInput, TextArea, Number Input, Checkbox and RadioButton components`}</li>
    </ul>
    <h3 {...{
      "id": "0240-beta",
      "style": {
        "position": "relative"
      }
    }}>{`0.24.0 (beta)`}<a parentName="h3" {...{
        "href": "#0240-beta",
        "aria-label": "0240 beta permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`29.3.2021`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v0.24.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Number input component`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Loading button component`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: 10 new icons`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Navigation component SSR logout bug`}</li>
    </ul>
    <h3 {...{
      "id": "0230-beta",
      "style": {
        "position": "relative"
      }
    }}>{`0.23.0 (beta)`}<a parentName="h3" {...{
        "href": "#0230-beta",
        "aria-label": "0230 beta permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`12.3.2021`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v0.23.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Time input to React package`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Navigation component server-side rendering bug`}</li>
    </ul>
    <h3 {...{
      "id": "0220-beta",
      "style": {
        "position": "relative"
      }
    }}>{`0.22.0 (beta)`}<a parentName="h3" {...{
        "href": "#0220-beta",
        "aria-label": "0220 beta permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`4.3.2021`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v0.22.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Date input with a date picker to React package`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Removed unwanted footer border in Safari browser`}</li>
    </ul>
    <h3 {...{
      "id": "0210-beta",
      "style": {
        "position": "relative"
      }
    }}>{`0.21.0 (beta)`}<a parentName="h3" {...{
        "href": "#0210-beta",
        "aria-label": "0210 beta permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`19.1.2021`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v0.21.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Search input to Core package`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Updated statuses of multiple components`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Multiple bug fixes`}</li>
    </ul>
    <h3 {...{
      "id": "0200-beta",
      "style": {
        "position": "relative"
      }
    }}>{`0.20.0 (beta)`}<a parentName="h3" {...{
        "href": "#0200-beta",
        "aria-label": "0200 beta permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`5.1.2021`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v0.20.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New component Tabs`}</li>
    </ul>
    <h3 {...{
      "id": "0190-beta",
      "style": {
        "position": "relative"
      }
    }}>{`0.19.0 (beta)`}<a parentName="h3" {...{
        "href": "#0190-beta",
        "aria-label": "0190 beta permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`22.12.2020`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v0.19.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New component Accordion`}</li>
    </ul>
    <h3 {...{
      "id": "0180-beta",
      "style": {
        "position": "relative"
      }
    }}>{`0.18.0 (beta)`}<a parentName="h3" {...{
        "href": "#0180-beta",
        "aria-label": "0180 beta permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`8.12.2020`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v0.18.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New component Search field`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New pattern Form validation`}</li>
    </ul>
    <h3 {...{
      "id": "0170-beta",
      "style": {
        "position": "relative"
      }
    }}>{`0.17.0 (beta)`}<a parentName="h3" {...{
        "href": "#0170-beta",
        "aria-label": "0170 beta permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`24.11.2020`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v0.17.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New component Container`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Koros to the Core package`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Removed non-semantic rounded corners from all of the components`}</li>
    </ul>
    <h3 {...{
      "id": "0160-beta",
      "style": {
        "position": "relative"
      }
    }}>{`0.16.0 (beta)`}<a parentName="h3" {...{
        "href": "#0160-beta",
        "aria-label": "0160 beta permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`13.11.2020`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v0.16.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New components Loading spinner, Selection group, Tag`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Accessibility issues in the Notification component`}</li>
    </ul>
    <h3 {...{
      "id": "0150-beta",
      "style": {
        "position": "relative"
      }
    }}>{`0.15.0 (beta)`}<a parentName="h3" {...{
        "href": "#0150-beta",
        "aria-label": "0150 beta permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`29.10.2020`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v0.15.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: First pattern Forms`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New components Tooltip, Card`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New icons, fill variants for notification icons`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Colour token names and values of light/dark variants`}</li>
      <li parentName="ul"><strong parentName="li">{`Reworked`}</strong>{`: Visual style of some icons to more accessible`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Multiple accessibility improvements to existing components. `}<em parentName="li">{`All released components have now passed the accessibility audit.`}</em></li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Multiple bug fixes existing components. `}</li>
    </ul>
    <h3 {...{
      "id": "0140-alpha",
      "style": {
        "position": "relative"
      }
    }}>{`0.14.0 (alpha)`}<a parentName="h3" {...{
        "href": "#0140-alpha",
        "aria-label": "0140 alpha permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`15.10.2020`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v0.14.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New component Footer`}</li>
      <li parentName="ul"><strong parentName="li">{`Reworked`}</strong>{`: Dropdown has gone through a complete overhaul, and the component was divided into two components; Select and Combobox.`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Multiple accessibility improvements to existing components`}</li>
    </ul>
    <h3 {...{
      "id": "0130-alpha",
      "style": {
        "position": "relative"
      }
    }}>{`0.13.0 (alpha)`}<a parentName="h3" {...{
        "href": "#0130-alpha",
        "aria-label": "0130 alpha permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`1.9.2020`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v0.13.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New components Navigation, Logo`}</li>
      <li parentName="ul"><strong parentName="li">{`Reworked`}</strong>{`: Notification component`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: You can now import specific HDS React components instead of having to import the whole package`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Breakpoint and container width tokens slightly changed to allow bigger outside margins in edge cases`}</li>
      <li parentName="ul"><strong parentName="li">{`Fixed`}</strong>{`: Multiple bug fixes and improvements to existing components`}</li>
    </ul>
    <h3 {...{
      "id": "0120-alpha",
      "style": {
        "position": "relative"
      }
    }}>{`0.12.0 (alpha)`}<a parentName="h3" {...{
        "href": "#0120-alpha",
        "aria-label": "0120 alpha permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`14.8.2020`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v0.12.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New component Status label`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Core `}<inlineCode parentName="li">{`.css`}</inlineCode>{` files are now available as minified and non-minified version`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: Required field indicator styles to the Core TextInput and TextArea components`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Removed all global styles`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Removed all component-specific design tokens`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Updated typography tokens to allow variable heading scale`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Changes to breakpoint and content width tokens`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Small changes to UI status colours to achieve better accessibility`}</li>
    </ul>
    <h3 {...{
      "id": "0110-alpha",
      "style": {
        "position": "relative"
      }
    }}>{`0.11.0 (alpha)`}<a parentName="h3" {...{
        "href": "#0110-alpha",
        "aria-label": "0110 alpha permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`2.6.2020`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v0.11.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New icons`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New design tokens for breakpoints and maximum content widths`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Updated HDS icon library and documentation`}</li>
    </ul>
    <h3 {...{
      "id": "0100-alpha",
      "style": {
        "position": "relative"
      }
    }}>{`0.10.0 (alpha)`}<a parentName="h3" {...{
        "href": "#0100-alpha",
        "aria-label": "0100 alpha permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><em parentName="p">{`26.5.2020`}</em>{` - `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v0.10.0" mdxType="ExternalLink">{`Release notes`}</ExternalLink></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New components Button, Checkbox, Dropdown, Koros, Radio button, Text input, and Text area`}</li>
      <li parentName="ul"><strong parentName="li">{`Added`}</strong>{`: New design tokens for Colour, Typography, and Spacing`}</li>
      <li parentName="ul"><strong parentName="li">{`Changed`}</strong>{`: Updated documentation site with new content for Getting started, Guidelines, Visual elements, About and Contributing sections`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      